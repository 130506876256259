import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { setHeaderToken } from '../../ApiHelpers/axios';
import UserPool from '../../ApiHelpers/UserPool';
import jwt from 'jwt-decode';

export const signup = data => async dispatch =>
  new Promise((resolve, reject) => {
    UserPool.signUp(data.email, data.password, [], null, (err, result) => {
      if (err) {
        resolve({ status: false, message: err.message });
      } else {
        dispatch({ type: 'SIGNUP' });
        resolve({ status: true });
      }
    });
  });

export const login = credentials => async dispatch =>
  new Promise(resolve => {
    const user = new CognitoUser({ Username: credentials.email, Pool: UserPool });
    const authDetails = new AuthenticationDetails({
      Username: credentials.email,
      Password: credentials.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: async () => {
        await dispatch(getProfile());
        dispatch({ type: 'LOGIN' });
        resolve('login');
      },

      onFailure: err => {
        resolve(err.message);
      },

      newPasswordRequired: data => {
        console.log('newPasswordRequired:', data);
        dispatch({
          type: 'LOGIN',
          payload: data,
        });
        resolve('newPassword');
      },
    });
  });

  const getProfile = () => dispatch =>
  new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();
    if (user) {
      return user.getSession((err, session) => {
        if (err) resolve(false);

        return user.getUserAttributes((error, attributes1) => {
          if (error) resolve(false);

          const results = {};
          for (const attribute of attributes1) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }
          const token = session.getIdToken().getJwtToken();
          const jwtData = jwt(token);
          const [userType] = jwtData['cognito:groups'];

          dispatch({
            type: 'GET_PROFILE',
            payload: { ...results, token, userType },
          });
          setHeaderToken(token);
          resolve(results);
        });
      });
    }
    resolve(false);
    return false;
  });


// export const loadUser = () => async dispatch => {
//   const profile = await dispatch(getProfile());
//   console.log('load profile:', profile);
//   if (!profile) {
//     dispatch({ type: 'NEW_USER' });
//     return false;
//   }
//   return true;
// };

export const logout = () => async dispatch => {
  const user = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
  dispatch({ type: 'LOGOUT' });
  return true;
};

export const forgotPassword = credentials => async dispatch =>
  new Promise(resolve => {
    const user = new CognitoUser({ Username: credentials.email, Pool: UserPool });
    user.forgotPassword({
      onSuccess: () => {
        resolve('success');
      },

      onFailure: err => {
        resolve(err.message);
      },

      inputVerificationCode: () => {
        resolve('send');
      },
    });
  });

export const resetPasswordWithCode = credentials => async dispatch =>
  new Promise(resolve => {
    const user = new CognitoUser({ Username: credentials.email, Pool: UserPool });
    user.confirmPassword(credentials.code, credentials.password, {
      onSuccess: () => {
        resolve('success');
      },
      onFailure: err => {
        resolve(err.message);
      },
    });
  });
