import { createRequestFunc } from "./AxiosInstance";

export const getJobsApi = async (data) => {
    return await createRequestFunc("get", `jobs?${data}`);
};
export const putPriorityApi = async (data) => {
  let apidata = {
    priority: data.priority,
    email: data.email,
  };
  return await createRequestFunc("put", `jobs/${data.id}`, apidata);
};
export const putNotesApi = async (data) => {
  return await createRequestFunc("put", `jobs/${data.id}`, {
    notes: data.notes,
  });
};

//Organisation API

export const getOganisations = async () => {
  return await createRequestFunc("get", `orgs`);
};
