import axios from "axios";
import API from "../api";

const axiosInstance = axios.create({
  baseURL: `${API.backendApi}/`,
});

const getHeader = () => {
  const headers = { "Content-Type": "application/json" };
  return headers;
};
export function createRequestFunc(methods, url, body, header) {
  let config = {
    method: methods,
    url,
    headers: getHeader(),
    data: body,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
