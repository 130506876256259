const initialState = {
  jobData: [],
};
const JobReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_JOB":
      return { ...state, jobData: [action.payload] };
   
      case "EDIT_JOB_PRIORITY":
        let updateData = [];
        state.jobData[0].map((item) => {
          if (action.payload.id === item.jobId) {
            updateData = [
              ...updateData,
              { ...item, priority: action.payload.priority },
            ];
          } else {
            updateData.push(item);
          }
          return null
        });
        return { ...state, jobData: [updateData] };
    default:
      return state;
  }
};
export default JobReducers;
