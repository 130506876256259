import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://' + process.env.REACT_APP_API_BASE_URL + '/v1/'
  baseURL: 'https://' +'portal.api.dev.hosta.ai/v1/'
});


const simpleInstance = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
  },
});

export const setHeaderToken = token => {
  if (token) {
    instance.defaults.headers.common.Authorization = `${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

[instance, simpleInstance].forEach(cur =>
  cur.interceptors.request.use(
    request => {
      const { baseURL, headers, method, url, data } = request;
      console.debug('Request', { baseURL, headers, method, url, data });
      return request;
    },
    error => {
      console.debug('Request Error', error);
      console.debug('Request Failed', error.request.data.message);
      return Promise.reject(error);
    }
  )
);

[instance, simpleInstance].forEach(cur =>
  cur.interceptors.response.use(
    response => {
      const { config, data, headers } = response;
      const { baseURL, method, url } = config;

      console.debug('Response', {
        config: {
          baseURL,
          headers: config.headers,
          method,
          url,
          data: config.data,
        },
        data,
        headers,
      });
      return response;
    },
    error => {
      console.debug('Response Error', error);
      console.debug('Response Error', error.response.data.message);
      return Promise.reject(error);
    }
  )
);

export const api = simpleInstance;

export default instance;
