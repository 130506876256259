import { configureStore } from "@reduxjs/toolkit";
import {applyMiddleware} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { persistReducer,persistStore } from "redux-persist";
import RootReducer from "./Reducers/RootReducer";
const persistConfig={
    key:'root',
    storage
}
const persistedReducer=persistReducer(persistConfig,RootReducer);
const Store=configureStore({reducer:persistedReducer},composeWithDevTools(applyMiddleware()));
const persistor=persistStore(Store);
export {Store,persistor};

