import "./App.scss";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes } from "react-router";
import MainComponent from "./components/Dashboard/MainComponent/MainComponent";
import SignUpScreen from "./components/AuthComponents/SignUpScreen/SignUpScreen";
import { LoginScreen } from "./components/AuthComponents/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "./components/AuthComponents/ForgotPasswordScreen/ForgotPasswordScreen";
import ResetPassword from "./components/AuthComponents/ResetPasswordScreen/ResetPassword";
import SignUpConfirmation from "./components/AuthComponents/SignUpScreen/SignUpConfirmation";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);

  function ProtectedRoutes({ children }) {
    return isAuthenticated ? children : <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/login" />} />
      <Route path="/sign-up" element={<SignUpScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sign-up-confirmation" element={<SignUpConfirmation />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoutes>
          <MainComponent />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
}

export default App;
