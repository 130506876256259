const initialState = {
  filterData: [],
};
const FilterReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_FILTER":
      return { ...state, filterData: [action.payload] };
    case "DELETE_FILTER":
      return {
        ...state,
        filterData: [],
      };
    case "EDIT_FILTER":
      let updateData = [];
      state.filterData.map((item) => {
        if (action.payload.id === item.id) {
          updateData = [
            ...updateData,
            { ...item, filter: action.payload.filter },
          ];
        } else {
          updateData.push(item);
        }
        return null
      });
      return { ...state, filterData: [updateData] };
    default:
      return state;
  }
};
export default FilterReducers;
