import { Form, Accordion } from "react-bootstrap";
import "./DropDownComponent.scss"
export const DropDownComponent = (props) => {
  const { title, name, data, eventKey, filter, handleradioSelect } = props;
  console.log("data",data);
  return (
    <Accordion.Item eventKey={eventKey}>
      {filter.filter((fil) => fil.Type === name).length > 0 ? (
        <Accordion.Header>
          <p className="hoverTitle">{title}</p>
        </Accordion.Header>
      ) : (
        <Accordion.Header>{title}</Accordion.Header>
      )}     
      <Accordion.Body>
        {data.map((item, key) => (
          <Form.Check
            checked={
              filter.filter(
                (fil) => fil.value === `${item}` && fil.Type === name
              ).length > 0
                ? true
                : false
            }
            className="form-radio"
            onChange={handleradioSelect}
            label={title === "Prioritization" ? `≥ ${item}` : item}
            value={item}
            type="radio"
            name={name}
            id={item}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};
