export const Add_Filter = (data) => {
    return {
      type: "ADD_FILTER",
      payload: data,
    };
  };
  export const Delete_Filter = () => {
    return {
      type: "DELETE_FILTER",
    };
  };
  export const Edit_Filter = (data) => {
    return {
      type: "EDIT_FILTER",
      payload: data,
    };
  };
  export const Add_Job = (data) => {
    return {
      type: "ADD_JOB",
      payload: data,
    };
  };
  export const Edit_Job_Priority = (data) => {
    return {
      type: "EDIT_JOB_PRIORITY",
      payload: data,
    };
  };