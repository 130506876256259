import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { setHeaderToken } from './axios';

const poolData = {
  // UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  // ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  UserPoolId: 'us-east-2_q73FOhLQ1',
  ClientId: '56tlfuidt9tlorahgqdi2ochru',
};

const UserPool = new CognitoUserPool(poolData);

export const globalAPICallback = cb =>
  new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser();
    if (user) {
      return user.getSession(async (err, session) => {
        if (err) {
          resolve(false);
          return false;
        }
        try {
          // const token = session.getIdToken().getJwtToken();
          // console.log('token =', token);
          const res = await cb();
          return resolve(res);
        } catch (error) {
          const status = error?.response?.status;
          if (status === 401) {
            user.refreshSession(session.getRefreshToken(), async (err, session1) => {
              if (err) {
                console.log('err', err);
                resolve(false);
                return false;
              } else {
                setHeaderToken(session1.getIdToken().getJwtToken());
                const res = await cb();
                return resolve(res);
              }
            });
          }
        }
      });
    }
    resolve(false);
    return false;
  });

export default UserPool;
