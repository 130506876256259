import { Accordion, Form, InputGroup } from "react-bootstrap";
import "./InputComponent.scss";
export const InputComponent = ({
  eventKey,
  title,
  name,
  handleInput,
  filter,
  remove,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      {filter.filter((fil) => fil.Type === name).length > 0 ? (
        <Accordion.Header>
          <p className="hoverTitle">{title}</p>
        </Accordion.Header>
      ) : (
        <Accordion.Header>{title}</Accordion.Header>
      )}
      <Accordion.Body>
        <span className="mb-3 search_input">
          <input
            aria-label={name}
            aria-describedby="inputGroup-sizing-default"
            placeholder={title}
            className="filter__Input"
            name={name}
            defaultValue={
              filter.filter((fil) => fil.Type === name).length > 0
                ? filter.filter((fil) => fil.Type === name)[0].value
                : ""
            }
            value={filter.filter((fil) => fil.Type === name).length > 0
              ? filter.filter((fil) => fil.Type === name)[0].value
              : ""}
            onChange={handleInput}
          />
          <span
            id="input-icon"
            className="d-flex align-items-center pe-2"
            onClick={() =>
              filter.filter((fil) => fil.Type === name).length > 0 ?
              remove(filter.filter((fil) => fil.Type === name)[0].value)
              :null
            }
          ></span>
        </span>
      </Accordion.Body>
    </Accordion.Item>
  );
};
